@import "../bower_components/normalize.css/normalize";
@import "bootstrap/bootstrap";

@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-ExtraLight.ttf);
  font-weight: 100
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-Light.ttf);
  font-weight: 200
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-Regular.ttf);
  font-weight: 300
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-Medium.ttf);
  font-weight: 400
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-SemiBold.ttf);
  font-weight: 500
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-Bold.ttf);
  font-weight: 600
}
@font-face{
  font-family: "Manrope";
  src: url(/fonts/Manrope-ExtraBold.ttf);
  font-weight: 700
}

$green: #0E5F4C;
$light: #FDFCFA;
$brown: #3B2714;
$brown_second: #8B5A2B;
$black: #181B20;
$dark: #2E2E2E;


$burger-size: 22px;
$line-height: 3px;
$line-color: $brown;

*{
  font-family: Manrope;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.3;
  color: $brown;
  font-weight: 300;
}
body{
  background: #F6F5F2;
}
.container{
  position: relative;
  max-width: 1400px;
  padding-left: 30px;
  padding-right: 30px;
}
.container-fluid{
  position: relative;
  padding: 0;
}
a{
  text-decoration: none;
}
input,button{
  &:focus{
    outline: none;
  }
}
a,button{
  &:hover{
    cursor: pointer;
    text-decoration: none;
  }
}
body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background: #fff;
}
body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: $green;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,0.5);
}
.mainBlock{
  position: relative;
  width: 100%;
  overflow: hidden;
}
h1,h2,h3,h4,h5,.bigTitle{
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  span{
    color: $green;
    font-size: 32px;
    font-weight: 600;
    line-height: 1;
  }
}
.defaultButton{
  display: inline-flex;
  vertical-align: middle;
  margin: 0;
  transition: .1s linear;
  border-radius: 40px;
  padding: 11px 15px;
  background: $green;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid $green;
  svg{
    color: #fff;
    margin-left: 10px;
    *{
      color: #fff;
    }
  }
  &.light{
    background: transparent;
    color: $green;
    *{
      color: $green;
    }
  }
  &.blue{
    color: $green;
    *{
      color: $green;
    }
  }
  &+.defaultButton{
    margin-left: 12px;
  }
  &.transparent{
    background: transparent;
    color: $green;
  }
  &.linkStyle{
    background: transparent;
    border: 1px solid transparent;
    padding: 0;
    color: $green;
    svg{
      color: $green;
      height: 24px;
      width: 24px;
      vertical-align: middle;
      margin: -2px 0 0 6px;
      *{
        color: $green;
      }
    }
  }
}

.bigLabel{
  display: inline-block;
  position: relative;
  margin: 0 auto 8px;
  padding: 0;
  span{
    color: $brown_second;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    z-index: 2;
    display: block;
    margin: 0;
    padding: 0;
  }
}

.sbWeight{
  font-weight: 500;
}
.bWeight{
  font-weight: 600;
}



header{
  position: relative;
  display: block;
  margin: 0;
  padding: 32px 0;
  width: 100%;
  max-width: 100%;
  background: #F7F6F3;
  border-radius: 0;
  overflow: hidden;
  box-shadow: 0 4px 7px rgba(134,131,125,.15);
  .container{
    ul.menu{
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      li{
        display: inline-flex;
        flex-grow: 1;
        margin: 0 10px 0 0;
        a{
          display: flex;
          margin: 0;
          color: $black;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
:root {
  --side-padding: max(20px, calc((100vw - 1400px) / 2 + 20px));
}
#mainBlock{
  .container{
    padding-top: 80px;
    padding-bottom: 80px;
    h1,h2,h3,h4{
      display: block;
      margin: 0 auto 40px;
    }
  }
  .firstBlock{
    position: relative;
    width: 100%;
    background: url('/img/bkg.png')no-repeat;
    background-size: cover;
    background-position: center;
    .container{
      padding-bottom: 0;
      p.text{
        display: table;
        margin: 0 auto 48px;
        font-size: 18px;
        line-height: 125%;
      }
      .mainImage{
        display: block;
        margin: 60px auto 0;
        width: 80%;
        transition: .2s ease-out;
      }
    }
  }
  .aboutBlock{
    .container{
      position: relative;
      .bkgBlock{
        background: #EFEEE8;
        border-radius: 24px;
        padding: 24px;
        box-shadow: 0 0 7px rgba(46,46,46,.20);
      }
      img{
        display: block;
        max-width: 100%;
      }
      p.text{
        font-size: 18px;
        display: block;
        margin: 0;
        &.bWeight{
          font-size: 22px;
        }
        &+p.text{
          margin-top: 24px;
        }
      }
    }
  }

  .processBlock{
    .tabs{
      display: flex;
      margin: 0 auto 40px;
      text-align: center;
      padding: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
      button{
        display: inline-flex;
        margin: 0;
        padding: 0 16px 10px;
        background: transparent;
        color: #706F6D;
        font-weight: 400;
        border: none;
        border-bottom: 2px solid #706F6D;
        &.active{
          color: $brown_second;
          border-color: $brown_second;
          font-weight: 500;
        }
      }
    }
    .tabBody{
      display: none;
      position: relative;
      width: 100%;
      padding: 0;
      margin: 0;
      &.opened{
        display: block;
      }
      p.name{
        display: block;
        margin: 0 0 20px;
        font-size: 30px;
      }
      p.text{
        font-size: 18px;
        display: block;
        margin: 0;
      }
      img{
        display: block;
        width: 100%;
        border-radius: 16px;
      }
    }
  }
  .advantagesBlock{
    background: linear-gradient(180deg, rgba(246,245,242,1) 0%, rgba(237,231,219,1) 90%);
    .advantagesList{
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      gap: 20px;
      .block{
        display: inline-flex;
        flex: 1;
        margin: 0;
        position: relative;
        border-radius: 24px;
        background: #FDFAF4;
        padding: 32px 24px;
        min-height: 260px;
        box-shadow: 0 0 7px rgba(46,46,46,.05);
        flex-direction: column;
        p.name{
          font-size: 24px;
          margin: 0 0 16px;
          line-height: 115%;
        }
        p.text{
          margin: 0;
        }
      }
    }
  }

  .casesBlock{
    background: linear-gradient(180deg, rgba(237,231,219,1) 0%, rgba(246,245,242,1) 85%);
    .casesList{
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      gap: 40px;
      .block{
        display: inline-flex;
        flex: 1;
        margin: 0;
        position: relative;
        flex-direction: column;
        img{
          width: 100%;
          border-radius: 20px;
          border: 2px solid #E0DCD9;
          margin: 0 0 24px;
        }
        p.name{
          display: block;
          margin: 0 0 12px;
          font-size: 22px;
        }
        p.text{
          display: block;
          margin: 0;
          max-width: 95%;
        }
      }
    }
  }

  .customRow{
    margin-left: -10px;
    margin-right: -10px;
    &>div{
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .priceBlock{
    h3{
      display: block;
    }
    .periodButtons{
      display: flex;
      margin: 0 auto;
      text-align: center;
      padding: 0;
      width: 100%;
      justify-content: center;
      align-items: center;
      button{
        display: inline-flex;
        margin: 0;
        padding: 0 16px 10px;
        background: transparent;
        color: #706F6D;
        font-weight: 400;
        border: none;
        border-bottom: 2px solid #706F6D;
        &.active{
          color: $green;
          border-color: $green;
          font-weight: 500;
        }
      }
    }
    .forYearTitle{
      display: block;
      margin: 16px auto 40px;
      text-align: center;
      color: $green;
      font-weight: 400;
    }
    .bkgBlock{
      background: #EFEEE8;
      border-radius: 24px;
      padding: 24px;
      box-shadow: 0 0 7px rgba(46,46,46,.20);
    }
    .block{
      display: block;
      position: relative;
      width: 100%;
      border-radius: 16px;
      background: $light;
      border: 1px solid $light;
      padding: 24px 24px 220px;
      margin: 0 0 24px;
      p.name{
        display: block;
        font-size: 20px;
        margin: 0 0 24px;
      }
      p.price{
        font-size: 16px;
        margin: 0;
        color: #706F6D;
        span{
          font-size: 24px;
          color: $brown;
          font-weight: 400;
        }
        &.forYear{
          display: none;
        }
        &.secondStyle{
          color: $brown;
          font-size: 14px;
          line-height: 120%;
          margin-top: -2px;
        }
      }
      .textList{
        position: relative;
        display: block;
        width: 100%;
        padding-top: 24px;
        margin: 24px 0 0;
        min-height: 500px;
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #3B2714;
          opacity: 10%;
        }
        p{
          display: block;
          margin: 0;
          padding: 0 0 0 18px;
          font-size: 14px;
          position: relative;
          &:before{
            content: '';
            display: block;
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $brown;
            left: 0;
            top: 8px;
            opacity: 0.7;
          }
          &+p{
            margin-top: 12px;
          }
        }
      }
      .bottomBlock{
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0 24px 24px;
        width: 100%;
        .bottomText{
          display: block;
          margin: 0 0 24px;
          font-size: 14px;
        }
        button{
          display: block;
          width: 100%;
          margin: 0;
        }
      }
      &.styleLast{
        padding-bottom: 24px;
        margin-bottom: 0;
        p.descr{
          display: block;
          position: relative;
          margin: 24px 0 0;
          padding: 24px 0 0;
          font-size: 14px;

          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #3B2714;
            opacity: 10%;
          }
        }
        .bottomBlock{
          position: relative;
          padding: 0;
          margin: 24px 0 0;
          button{
            width: auto;
            max-width: 100%;
            padding-left: 35px;
            padding-right: 35px;
          }
        }
      }
    }
  }

  .postsBlock{
    .postsList {
      display: block;
      width: 100%;
      position: relative;
      overflow-x: auto;
      overflow-y: visible;
      padding-left: var(--side-padding);
      padding-right: var(--side-padding);
      transition: padding 0.3s;
      white-space: nowrap;
      margin: 0;
      scrollbar-width: none;
      .block{
        vertical-align: middle;
        display: inline-block;
        width: 410px;
        position: relative;
        margin: 0;
        white-space: normal;
        background: #FDFCFA;
        border-radius: 24px;
        padding: 24px;
        border: 1px solid transparent;
        box-shadow: 0 0 7px rgba(46,46,46,.05);
        .headBlock{
          display: block;
          width: 100%;
          position: relative;
          padding: 0;
          margin: 0 0 16px;
          white-space: nowrap;
          p{
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0 24px 0 0;
            width: calc(100% - 100px);
            font-size: 18px;
            font-weight: 600;
            white-space: normal;
            line-height: 120%;
          }
          img{
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding: 0;
            width: 100px;
            height: 100px;
            object-fit: cover;
            object-position: center;
            border-radius: 20px;
            border: 2px solid #e3e3e3;
          }
        }
        p.descr{
          display: block;
          line-height: 120%;
          margin: 0 0 16px;
        }
        &+.block{
          margin-left: 24px;
        }
      }
    }
  }

  .faqBlock{
    background: linear-gradient(180deg, rgba(246,245,242,1) 47%, rgba(237,231,219,1) 90%);
    .faqList{
      display: block;
      width: 100%;
      position: relative;
      .item{
        display: block;
        width: 100%;
        position: relative;
        border: none;
        padding: 24px 0;
        &:after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          background: $brown;
          width: 100%;
          height: 1px;
          opacity: 0.10;
        }
        &:hover{
          cursor: pointer;
        }
        svg{
          position: absolute;
          right: 0;
          top: 24px;
          height: 24px;
          width: 24px;
          color: $brown;
          path{
            color: $brown;
          }
        }
        p.name{
          display: block;
          margin: 0;
          font-size: 18px;
          line-height: 120%;
        }
        p.text{
          display: none;
          margin: 20px 0 0;
          font-size: 15px;
        }
        &.opened{
          svg{
            transform: rotate(180deg);
            color: $green;
            path{
              color: $green;
            }
          }
          p.name{
            color: $green;
            font-weight: 600;
          }
          p.text{
            display: block;
          }
        }
      }
    }
  }


}

footer{
  background: $dark;
  padding: 40px 0;
  width: 100%;
  position: relative;
  p.text{
    display: block;
    margin: 0;
    color: #CDCBC5;
    font-size: 16px;
  }
  p.label{
    color: #F5F2EB;
    font-size: 20px;
  }
  button.customModalButton{
    color: #F5F2EB;
    border-bottom: 1px solid;
  }
  img.resident{
    display: block;
    height: 35px;
    max-width: 90%;
    margin: 0;
  }
  .socialNetworksList{
    display: table;
    margin: 0;
    padding: 0;
    li{
      display: inline-table;
      vertical-align: middle;
      margin: 0;
      padding: 0;
      &+li{
        margin-left: 16px;
      }
      a{
        display: table;
        margin: 0;
        padding: 0;
        svg{
          height: 24px;
          width: 24px;
          color: #CDCBC5;
          *{
            color: #CDCBC5;
          }
        }
      }
    }
  }
  .line{
    display: block;
    width: 100%;
    height: 1px;
    background: #F5F2EB;
    opacity: 15%;
    margin: 40px 0;
  }
  p.asterasLabsText{
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #CDCBC5;
  }
}
div#mainBlock {
  position: relative;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 1100px) {
  header .container ul.menu {
    display: table;
    width: 80%;
    margin: 0 auto;
  }

  header .container ul.menu li {
    display: inline-table;
    flex-grow: unset;
    margin: 5px 10px;
  }

  header .container .defaultButton {
    float: right;
    font-size: 14px;
  }

  #mainBlock .firstBlock .col-12.col-sm-6 {
    max-width: 100%;
    flex: 100%;
    margin: 0;
  }

  #mainBlock .advantagesBlock .block p.name {
    font-size: 18px;
  }

  #mainBlock .casesBlock .casesList .block .content img {
    object-fit: contain;
    object-position: center bottom;
  }

  #mainBlock .casesBlock .casesList .block .content p.text {
    margin-bottom: 180px;
  }

  #mainBlock .priceBlock .block p.price span {
    font-size: 24px;
  }

  #mainBlock .priceBlock .block p.price {
    font-size: 16px;
  }

  #mainBlock .priceBlock .block p.name {
    font-size: 20px;
  }

  #mainBlock .priceBlock .block button {
    font-size: 14px;
  }
}

@media (max-width: 925px) {
  h1,h2,h3,h4,h5,.bigTitle{
    font-size: 24px;
    span{
      font-size: 24px;
    }
  }
  #mobileMenu{
    display: none!important;
  }
  header{
    padding: 0;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    position: sticky;
    top: 0;
    left: 0;
  }
  .burger {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 16px;
    cursor: pointer;
    width: $burger-size;
    height: $burger-size;
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    justify-content: center;
    .line {
      background-color: $line-color;
      height: $line-height;
      width: 100%;
      transition: all 0.3s ease;
      position: absolute;
      border-radius: 15px;
      &:nth-child(1) {
        top: 2px;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translate(0, -50%);
      }
      &:nth-child(3) {
        bottom: 2px;
      }
    }
  }
  body.burgerActive{
    overflow: hidden;
    #mobileMenu{
      display: block!important;
    }
    header{
      .burger{
        .line{
          width: 80%;
          background-color: $black!important;
        }
        .line:nth-child(1) {
          transform: rotate(45deg);
          top: ($burger-size - $line-height) / 2;
        }
        .line:nth-child(2) {
          opacity: 0;
        }
        .line:nth-child(3) {
          transform: rotate(-45deg);
          bottom: ($burger-size - $line-height) / 2;
        }
      }
    }
  }
  #mainBlock{
    .container{
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 15px;
      padding-right: 15px;
      h1,h2,h3,h4,h5,.bigTitle{
        margin-bottom: 24px;
      }
    }
  }
  #mainBlock .firstBlock h1 br {
    display: none;
  }

  #mainBlock .firstBlock .container p.text {
    margin-bottom: 40px;
  }

  #mainBlock .firstBlock .container p.text br {
    display: none;
  }

  .defaultButton+.defaultButton {
    margin: 24px auto 0;
    display: block;
  }

  #mainBlock .firstBlock .container .mainImage {
    margin: 40px auto;
  }

  #mainBlock .aboutBlock .container {
    padding-top: 40px;
  }

  .bigLabel {
    margin-bottom: 16px;
  }

  #mainBlock .aboutBlock .container img {
    display: block;
    margin-bottom: 40px;
  }

  #mainBlock .aboutBlock .container p.text {
    font-size: 16px;
    text-align: center;
  }

  #mainBlock .processBlock #processList .item {
    width: 300px;
  }

  #mainBlock .processBlock #processList .item+.item {
    margin-left: 12px;
  }

  #mainBlock .processBlock #processList .item p.name {
    font-size: 18px;
  }

  #mainBlock .processBlock #processList .item img {
    height: 300px;
    object-fit: cover;
    object-position: left center;
  }

  #mainBlock .processBlock #processList .item p {
    display: block;
    max-width: 95%;
  }

  #mainBlock .processBlock #processList .item p.text {
    margin: 0;
  }

  #mainBlock .processBlock #processList {margin-bottom: 40px;}

  #mainBlock .advantagesBlock .block {
    min-height: 0;
    padding-bottom: 50px;
    margin: 12px 0 0;
  }

  .container-fluid.casesBlock h3 {
    width: 75%;
  }

  #mainBlock .casesBlock .casesList {
    display: block;
    width: 100%;
    margin: 0;
    overflow: auto;
    white-space: nowrap;
  }

  #mainBlock .casesBlock .casesList .block {
    display: inline-block;
    width: 300px;
    flex-grow: unset;
    margin: 0;
    white-space: normal;
    vertical-align: top;
  }

  #mainBlock .casesBlock .casesList .block+.block {
    margin-left: 12px;
  }

  #mainBlock .casesBlock .casesList .block .content p.name {
    font-size: 18px;
  }

  #mainBlock .casesBlock .casesList .block .content {
    display: block;
    width: 100%;
    position: relative;
    min-height: 340px;
  }

  #mainBlock .casesBlock .casesList .block .content img {
    height: auto;
    bottom: 0;
    right: 0;
    left: unset;
    transform: unset;
  }

  #mainBlock .casesBlock .casesList .block .content p.text {
    margin-bottom: 0;
  }

  #mainBlock .priceBlock p.text {
    font-size: 16px;
    margin-bottom: 12px;
  }

  #mainBlock .priceBlock p.text br {
    display: none;
  }

  #mainBlock .priceBlock h3 {
    margin-bottom: 12px;
  }

  #mainBlock .priceBlock .block {
    min-height: 0;
    margin: 12px 0 0;
  }

  #mainBlock .priceBlock .block button {
    position: relative;
    bottom: unset;
    left: unset;
    margin: 40px 0 0;
    font-size: 16px;
  }

  #mainBlock .faqBlock .faqList .item p.name {
    font-size: 16px;
  }

  #mainBlock .faqBlock .faqList .item p.text {
    font-size: 14px;
  }

  #mainBlock .faqBlock .faqList .item svg {
    top: 32px;
  }

  footer p.label {
    display: block;
    margin: 32px 0 6px;
  }

  footer {
    padding-top: 8px;
    padding-bottom: 60px;
  }

  header {
    z-index: 15;
  }

  .burger {
    float: right;
  }
  body.burgerActive #mobileMenu {
    background: #fff;
    height: 100svh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 112px 15px 40px;
  }

  body.burgerActive #mobileMenu ul.menu {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body.burgerActive #mobileMenu ul.menu li {
    display: block;
    margin: 0;
    padding: 0;
  }

  body.burgerActive #mobileMenu ul.menu li a {
    display: block;
    padding: 10px 0;
    color: #222;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  body.burgerActive #mobileMenu button.defaultButton {
    position: absolute;
    bottom: 40px;
    left: 15px;
    display: block;
    width: calc(100% - 30px);
  }
  body.burgerActive #mobileMenu p.burgerMenuLabel {
    display: block;
    margin: 0 auto 30px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: $green;
  }

  .burger .line:nth-child(3) {
    width: 60%;
    right: 0;
  }

  body.burgerActive header .burger .line:nth-child(3) {
    width: 80%;
    right: unset;
  }



  //ShaiStyles
  header {
    padding: 22px 0;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  header a.logo img {
    display: block;
    height: 27px;
  }

  .bigTitle, h1, h2, h3, h4, h5 {}

  #mainBlock .firstBlock .container p.text {
    font-size: 16px;
    max-width: 95%;
  }

  .defaultButton+.defaultButton {
    margin: 12px auto 0;
  }

  body.burgerActive #mobileMenu p.burgerMenuLabel {
    color: #3B2714;
  }

  body.burgerActive #mobileMenu {
    background: #F6F5F2;
  }

  #mainBlock .firstBlock .container .mainImage {
    width: 100%!important;
    max-width: 100%;
    margin: 40px 0 0;
  }

  #mainBlock .firstBlock {
    background: url(/img/bkg-mob.png) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bigLabel {
    margin-bottom: 8px;
  }

  .bigLabel span {
    font-size: 13px;
  }

  #mainBlock .aboutBlock .container .bkgBlock {
    padding: 16px;
  }

  #mainBlock .aboutBlock .container img {
    margin-bottom: 24px;
  }

  #mainBlock .aboutBlock .container p.text {
    text-align: left;
  }

  #mainBlock .aboutBlock .container p.text.bWeight {
    font-size: 20px;
  }

  #mainBlock .aboutBlock .container p.text+p.text {
    margin-top: 16px;
  }

  #mainBlock .processBlock .tabs {
    display: block;
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  #mainBlock .processBlock .tabs button {
    font-size: 14px;
    padding: 0 8px 10px;
  }

  #mainBlock .processBlock .tabBody p.name {
    font-size: 22px;
    margin: 24px 0 12px;
    line-height: 120%;
  }

  #mainBlock .processBlock .tabBody p.text {
    font-size: 16px;
  }

  #mainBlock .processBlock .tabBody img {
    margin: 0;
  }

  #mainBlock .advantagesBlock .advantagesList {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    gap: unset;
  }

  #mainBlock .advantagesBlock .advantagesList .block {
    display: inline-block;
    vertical-align: top;
    width: 250px;
    white-space: normal;
    padding: 24px 20px;
    min-height: 200px;
  }

  #mainBlock .advantagesBlock .advantagesList .block+.block {
    margin-left: 15px;
  }

  #mainBlock .advantagesBlock .advantagesList .block p.name {
    font-size: 20px;
    margin: 0 0 12px;
  }

  #mainBlock .advantagesBlock .advantagesList .block p.text {
    font-size: 15px;
  }

  #mainBlock .casesBlock .casesList .block {
    width: 250px;
  }

  #mainBlock .casesBlock .casesList .block img {
    margin: 0 0 15px;
  }

  #mainBlock .casesBlock .casesList .block p.name {
    font-size: 20px;
    margin: 0 0 8px;
  }

  #mainBlock .casesBlock .casesList .block p.text {
    font-size: 15px;
  }

  #mainBlock .priceBlock h3 {
    margin-bottom: 40px;
  }

  #mainBlock .priceBlock .periodButtons button {
  }

  .mobileButtonsList button {
    border: 2px solid #FFFEFC;
    background: #FFFEFC !important;
    color: #181B20 !important;
    font-weight: 400;
  }

  .mobileButtonsList button + button {
    margin-left: 4px !important;
  }

  .mobileButtonsList button.active {
    border-color: #8B5A2B;
    color: #8B5A2B !important;
    font-weight: 600;
  }

  .mobileButtonsList {
    width: calc(100% + 15px);
    padding-right: 15px;
  }

  #mainBlock .priceBlock .bkgBlock {
    padding: 16px;
  }

  #mainBlock .priceBlock .block {
    margin: 0;
    display: none;
    padding-bottom: 24px;
  }

  #mainBlock .priceBlock .block.active {
    display: block;
  }

  #mainBlock .priceBlock .block .textList {
    min-height: 0;
  }

  #mainBlock .priceBlock .block .bottomBlock {
    position: relative;
    padding: 40px 0 0;
  }

  #mainBlock .priceBlock .block.styleLast .bottomBlock button {
    width: 100%;
  }

  #mainBlock .postsBlock .postsList .block {
    width: 300px;
    padding: 16px;
    vertical-align: top;
  }

  #mainBlock .postsBlock .postsList .block+.block {
    margin-left: 16px;
  }

  #mainBlock .postsBlock .postsList .block .headBlock p {
    font-size: 16px;
    width: calc(100% - 90px);
  }

  #mainBlock .postsBlock .postsList .block .headBlock img {
    width: 90px;
    height: 90px;
  }

  #mainBlock .postsBlock .postsList .block p.descr {
    font-size: 14px;
  }

  #mainBlock .postsBlock .postsList .block .headBlock {
    margin-bottom: 12px;
  }

  #mainBlock .faqBlock .faqList .item p.name {
    padding-right: 25px;
  }

  #mainBlock .faqBlock .faqList .item svg {
    top: 16px;
  }

  #mainBlock .faqBlock .faqList .item {
    padding: 18px 0;
  }

  footer p.label {
    font-size: 16px;
  }

  footer button.customModalButton {
    font-size: 15px;
  }

  footer p.text {
    font-size: 15px;
  }

  footer img.resident {
    margin: 10px 0 0;
    display: block;
  }

  footer .line {
    margin: 28px 0;
  }

  footer p.asterasLabsText {
    font-size: 15px;
    text-align: left;
  }
  #mainBlock .postsBlock .postsList .block .headBlock p {
    max-height: 80px;
    overflow: hidden;
  }
  .partnerBlock #partnersList {
    margin-bottom: 40px;
  }
}